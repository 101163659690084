import { addons } from '@storybook/addons';
import { create } from '@storybook/theming';

const theme = create({
  base: 'light',
  brandTitle: 'Qmee - Storybook',
  brandUrl: 'https://beta.qmee.com',
  brandImage: '/Logo.svg',
  colorPrimary: '#2bd675',
  colorSecondary: '#ff3366',
  appBg: '#F8F8FA',
  appContentBg: 'white',
});
addons.setConfig({
  theme: theme,
});
